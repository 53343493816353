import React from 'react';
import CircularProgressBar from './CircularProgressBar';

class CountDown extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            maxTime: 10,
            time: 10,
            isOn: false,
            percentage: 0
        }

        this.startCounter = this.startCounter.bind(this);
        this.stopCounter = this.stopCounter.bind(this);
        this.resetCounter = this.resetCounter.bind(this);
    }

    startCounter(){
        this.setState({
            isOn: true,
            time: this.state.time,
            progress: 0
        });
    }

    stopCounter(){
        this.setState({isOn: false});
        clearInterval(this.timer);
    }

    resetCounter(){
        const { maxTime } = this.state;
        this.setState({isOn: false, time: maxTime, progress: 0});
    }

    componentDidMount(){
        this.interval = setInterval(() => {

            const { time, maxTime } = this.state;

            if(time > 0){
                this.setState({time: time-1, progress: 100 - ((time-1) / maxTime * 100)});
            }
            else if(time <= 0){
                this.props.countDownZero();
                this.resetCounter();
            }

        }, 1000);
    }
    
    componentWillUnmount() {
        this.stopCounter();
    }

    render(){
        return(<div className="fixed bottom left ma-20"><CircularProgressBar text={this.state.time} percentage={this.state.progress} /></div>)
    }
}

export default CountDown;