import React from 'react';
import { Link } from 'react-router-dom';
import CountDown from './CountDown';

class Home extends React.Component{

    constructor(props){
        super(props);
        this.state = {pictureID: 0};
        this.counter = React.createRef();
        this.pictures = [];
        this.scrollTo = this.scrollTo.bind(this);
        this.countDownZero = this.countDownZero.bind(this);
    }

    componentDidMount(){
    }

    scrollTo(id){
        this.pictures[id].scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.setState({pictureID: id});
        this.counter.current.resetCounter();
    }

    countDownZero(){
        const { pictureID } = this.state;
        this.setState({pictureID: pictureID + 1});
        if(this.state.pictureID === 10)
            this.setState({pictureID: 0});

        this.scrollTo(this.state.pictureID);
    }

    render(){
    return(
        <div className="top full-height full-width absolute overflow-y-h">
            <CountDown ref={this.counter} countDownZero={this.countDownZero} />
            <section className="grid-home">
                <section className="">
                    <nav className="fixed destination-bar ml-20 vertical-middle">
                        <ul className="ma menu text-left ml-10">
                            <li className={"menu-item " + (this.state.pictureID===0 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(0)}>Iceland</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===1 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(1)}>New York</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===2 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(2)}>Grand Teton</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===3 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(3)}>Twin Falls</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===4 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(4)}>Monument Valley</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===5 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(5)}>Grand Canyon</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===6 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(6)}>Bryce</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===7 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(7)}>Los Angeles</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===8 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(8)}>Yosemite</Link></li>
                            <li className={"menu-item " + (this.state.pictureID===9 ? "active" : "")}><Link className="text-nu text-color-2 text-upper" onClick={() => this.scrollTo(9)}>San Francisco</Link></li>
                        </ul>
                    </nav>
                </section>
                <section ref={(ref) => {this.pictures[0] = ref;}} className="row-start-1 col-start-3 relative">
                    <img className="full-width" src="/images/Iceland.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">March 2015</span></p>
                </section>
                <section ref={(ref) => {this.pictures[1] = ref;}} className="row-start-2 col-start-3 relative">
                    <img className="full-width" src="/images/NYC.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[2] = ref;}} className="row-start-3 col-start-3">
                    <img className="full-width" src="/images/GrandTeton.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[3] = ref;}} className="row-start-4 col-start-3">
                    <img className="full-width" src="/images/TwinFalls.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[4] = ref;}} className="row-start-5 col-start-3">
                    <img className="full-width" src="/images/MonumentValley.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[5] = ref;}} className="row-start-6 col-start-3">
                    <img className="full-width" src="/images/GrandCanyon.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[6] = ref;}} className="row-start-7 col-start-3">
                    <img className="full-width" src="/images/Bryce.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[7] = ref;}} className="row-start-8 col-start-3">
                    <img className="full-width" src="/images/LA.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[8] = ref;}} className="row-start-9 col-start-3">
                    <img className="full-width" src="/images/Yosemite.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
                <section ref={(ref) => {this.pictures[9] = ref;}} className="row-start-10 col-start-3">
                    <img className="full-width" src="/images/SF.jpg" alt=""></img>
                    <p className="text-color-1 text-size-3 text-right m0 pt-10"><span className="text-weight-sl text-stretch-sc">September 2018</span></p>
                </section>
            </section>
        </div>
    );
    }
}

export default Home;