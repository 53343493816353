import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import Contact from './components/Contact';
import Menu from './components/Menu';
import Home from './components/Home';

function App (){
    return (
      <div>
        <div className="z-index-n10 full-height full-width fixed background background-color-1 top"></div>
        <Menu />
        <div className="App">
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route path="/contact"><Contact /></Route>
          </Switch>
        </div>
      </div>
    );
  
}

export default App;
