// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-bar {
  background-color: #F9F8F4; }

.menu {
  width: -moz-fit-content;
  width: fit-content;
  right: 0px;
  position: absolute; }

.menu-item {
  display: inline-block;
  padding: 0px 10px; }
`, "",{"version":3,"sources":["webpack://./src/Menu.scss","webpack://./src/utilities/variables.scss"],"names":[],"mappings":"AAEA;EACI,yBCGwB,EAAA;;ADD5B;EACI,uBAAkB;EAAlB,kBAAkB;EAClB,UAAU;EACV,kBAAkB,EAAA;;AAGtB;EACI,qBAAqB;EACrB,iBAAiB,EAAA","sourcesContent":["@import \"utilities/variables.scss\";\r\n\r\n.menu-bar{\r\n    background-color: $background-color-1;\r\n}\r\n.menu{\r\n    width: fit-content;\r\n    right: 0px;\r\n    position: absolute;\r\n}\r\n\r\n.menu-item{\r\n    display: inline-block;\r\n    padding: 0px 10px;\r\n}","$color-1: #D3D0C1;\r\n$color-2: #3D3D3D;\r\n$color-3: #F9F8F4;\r\n$text-color-1: #3D3D3D;\r\n$text-color-2: #D3D0C1;\r\n$text-color-3: #F9AA4B;\r\n$background-color-1: #F9F8F4;\r\n$background-color-2: #D3D0C1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
