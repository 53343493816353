import React from 'react'
import '../Menu.scss'
import { Link, Switch, Route } from 'react-router-dom'

class Menu extends React.Component {
    render() {
        return(
        <div className="fixed menu-bar full-width z-index-10 pt-20">
            <nav className="menu vertical-middle">
                <ul className="ma">
                    <Switch>
                        <Route exact path="/">
                            <li className="menu-item"><Link className="text-nu text-color-1 mr-30" to="/contact">/&emsp;&emsp;Contact</Link></li>
                        </Route>
                        <Route path="/contact">
                            <li className="menu-item"><Link className="text-nu text-color-1 mr-30" to="/">/&emsp;&emsp;Work</Link></li>
                        </Route>
                    </Switch>
                </ul>
            </nav>
            <p className="text-color-1 text-size-30 text-weight-sl text-center ma title">RBRTVRNS</p>
        </div>
        );
    };
}

export default Menu;