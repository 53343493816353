import React from 'react';

function Contact(){
    return(
        <div>
                <section className="inner-grid col-start-2 text-left">
                    <section className="row-start-2 col-start-3 col-end-2 text-center">
                        <p className="text-size-30 text-color-1 text-stretch-sc text-weight-b m0">Hello</p>
                    </section>
                    <section className="row-start-3 col-start-2 col-end-4 text-center">
                        <p className="text-size-20 text-color-2 text-stretch-sc text-weight-b ma">I’m <span className="text-color-3">Robert</span>, a creator based in <span className="text-color-3">The Netherlands.</span></p>
                    </section>
                    <section className="row-start-5 col-start-3 col-end-1">
                            <p className="text-size-30 text-color-1 text-stretch-sc text-weight-b ma">Contact me</p>
                    </section>
                    <section className="row-start-5 col-start-4">
                            <a className="text-size-15 text-color-2 text-nu text-left block link-anim-1" href="mailto:robert@vriens.me" target="blank">Email&emsp;/</a>
                            <a className="text-size-15 text-color-2 text-nu text-left block link-anim-1" href="https://www.instagram.com/rbrtvrns/" target="blank">Instagram&emsp;/</a>
                            <a className="text-size-15 text-color-2 text-nu text-left block link-anim-1" href="https://www.linkedin.com/in/robert-vriens-a7741b97/" target="blank">LinkedIn&emsp;/</a>
                            <a className="text-size-15 text-color-2 text-nu text-left block link-anim-1" href="https://twitter.com/Mrhankey91" target="blank">Twitter&emsp;/</a>
                    </section>
                </section>
        </div>
    );
}

export default Contact;